@import '../../../../scss/theme-bootstrap';

.loyalty-popup {
  #{$rdirection}: 0;
  background: $color-grey-light;
  display: flex;
  letter-spacing: 1px;
  padding: 10px;
  position: relative;
  top: 0;
  width: auto;
  z-index: 99;
  @include breakpoint($medium-up) {
    #{$rdirection}: 10px;
    @include swap_direction(padding, 8px 16px 8px 8px);
    background: $color-white;
    height: auto;
    min-width: 402px;
    position: absolute;
    top: 40px;
    width: 100%;
  }
  &.active {
    display: block;
    @include breakpoint($medium-up) {
      display: none;
    }
  }
  &__close {
    #{$rdirection}: 10px;
    background: none;
    height: 20px;
    position: absolute;
    top: 10px;
    width: 20px;
    @include breakpoint($medium-up) {
      #{$rdirection}: 16px;
      top: 8px;
    }
    &::before,
    &::after {
      background: $color-black;
      content: '';
      display: block;
      position: absolute;
      transform: rotate(45deg);
    }
    &::before {
      bottom: 0;
      height: 20px;
      width: 2px;
    }
    &::after {
      #{$ldirection}: 2px;
      bottom: 9px;
      height: 2px;
      width: 20px;
    }
  }
  &__arrow {
    #{$rdirection}: 20px;
    background: url('/media/images/global/util-dropdown-arrow.png') no-repeat 0 0;
    height: 12px;
    position: absolute;
    top: -12px;
    width: 25px;
    z-index: 10;
  }
  &__image {
    display: none;
    max-width: 165px;
    @include breakpoint($medium-up) {
      display: block;
      height: 283px;
      width: 165px;
    }
  }
  &__content {
    padding: 0;
    width: 80%;
    @include breakpoint($medium-up) {
      padding: 28px 15px 18px;
      width: auto;
    }
  }
  &__offer {
    color: $color-black;
    font-family: $bb-sans;
    font-size: 20px;
    font-weight: bold;
    line-height: 0.75;
    margin-bottom: 19px;
  }
  &__desc {
    color: $color-black;
    font-family: $bbtext-regular;
    font-size: 12px;
    line-height: 1.25;
    margin-bottom: 0;
    text-align: $ldirection;
    @include breakpoint($medium-up) {
      margin-bottom: 17px;
    }
    span.loyalty-popup__desc__content {
      display: inline-block;
      font-weight: bold;
      @include breakpoint($medium-up) {
        font-weight: normal;
      }
    }
  }
  .loyalty-email-signup {
    margin-top: 12px;
    width: auto;
    &__error {
      color: $color-red;
      font-size: 12px;
      line-height: 1.5;
      margin-bottom: 10px;
      text-align: $ldirection;
    }
    .loyalty-terms {
      color: $color-warm-grey;
      font-family: $bbtext-regular;
      font-size: 12px;
      line-height: 1.25;
      text-decoration: underline;
      @include breakpoint($medium-up) {
        #{$rdirection}: 0;
        position: relative;
      }
    }
    .loyalty-signup-info {
      border-bottom: 1px solid $color-grey-footer;
      display: flex;
      margin-bottom: 10px;
      @include breakpoint($medium-up) {
        border-bottom: 0;
        margin-bottom: 19px;
      }
    }
    .email-signup {
      background: none;
      border: 0;
      font-size: 12px;
      height: auto;
      line-height: 1.25;
      padding: 0;
      width: 100%;
      @include breakpoint($medium-up) {
        background: $color-white;
        border: 1px solid $color-black;
        height: 40px;
        padding: 8px;
      }
      &::placeholder {
        color: $color-black;
      }
    }
    .email-submit {
      background: $color-grey-light;
      color: $color-black;
      display: inline-block;
      font-size: 13px;
      font-weight: bold;
      height: auto;
      line-height: 1.25;
      margin-bottom: 0;
      max-width: 90px;
      text-align: center;
      width: auto;
      @include breakpoint($medium-up) {
        background: $color-black;
        color: $color-white;
        height: 40px;
        width: 100%;
      }
    }
    .loyalty-additional-info {
      color: $color-warm-grey;
      display: inline-block;
      float: $ldirection;
      font-family: $bbtext-regular;
      font-size: 12px;
      line-height: 1.25;
      margin-#{$ldirection}: 0;
      padding-top: 5px;
      text-decoration: underline;
      text-transform: capitalize;
      @include breakpoint($medium-up) {
        color: $color-black;
        float: none;
        margin-#{$ldirection}: 5px;
        padding-top: 0;
        text-transform: uppercase;
      }
    }
  }
}

.loyalty-popup--enabled {
  .site-header {
    height: 275px;
    @include breakpoint($medium-up) {
      height: auto;
    }
    .site-utils--sticky {
      .site-utils__promo {
        margin-top: 0;
      }
    }
  }
  &.viewcart {
    .site-utils__promo {
      display: block;
    }
  }
}
